import { useCallback, useMemo } from 'react';

import { ESearchFilters } from '@/constants/searchFilters';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { ERentalCategory } from '@/services/types/search/rentals/id';
import { guardedJsonParse } from '@/utility/json';

// we limit recent searches based on usage. By default we limit to the most
// recent MAX_RECENT_SEARCHES but when using auto complete user may see older
// searches exposed
export const MAX_RECENT_SEARCHES = 5;
const MAX_STORED_SEARCHES = 50;

type RecentSearchOption = {
  label: string;
  value: string;
  url: string;
};

function isRecentSearchFilter(obj: unknown): obj is RecentSearchOption {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const { label, url, value } = obj as RecentSearchOption;
  return typeof label === 'string' && typeof url === 'string' && typeof value === 'string';
}

function parsedObjectIsRecentSearchArray(obj: unknown): obj is RecentSearchOption[] {
  return Array.isArray(obj) && obj.every(item => isRecentSearchFilter(item));
}

function filterRecentSearchByRentalType(
  type: ERentalCategory,
  searchOptions: RecentSearchOption[],
) {
  // Filter recent search options based on rental type
  return searchOptions.filter(searchOption => {
    const queryParams = searchOption.url.split('?')?.[1];
    const searchParams = new URLSearchParams(queryParams);

    // If the rental type is RV and the rental category is not present,
    // or if the rental type matches the rental category, return true
    if (type === ERentalCategory.RV) {
      return (
        !searchParams.has(ESearchFilters.RENTAL_CATEGORY) ||
        searchParams.get(ESearchFilters.RENTAL_CATEGORY) === type
      );
    } else {
      // For other rental types, return true if the rental category matches the type
      return searchParams.get(ESearchFilters.RENTAL_CATEGORY) === type;
    }
  });
}

export function useRecentSearchesWithFilters(
  searchRentalType = ERentalCategory.RV,
): [RecentSearchOption[], { addRecentSearch: (search: RecentSearchOption) => void }] {
  const [recentSearches, { update }] = useLocalStorage('recentSearchesWithFilters');

  const recentSearchesParsed = useMemo(() => {
    return recentSearches
      ? guardedJsonParse(parsedObjectIsRecentSearchArray, recentSearches) || []
      : [];
  }, [recentSearches]);

  const filteredRecentSearches = useMemo(() => {
    return filterRecentSearchByRentalType(searchRentalType, recentSearchesParsed);
  }, [searchRentalType, recentSearchesParsed]);

  const addRecentSearch = useCallback(
    (recentSearch: RecentSearchOption) => {
      const updatedRecentSearches = recentSearchesParsed.filter(
        search => search.value !== recentSearch.value,
      );
      update(
        JSON.stringify([recentSearch, ...updatedRecentSearches].slice(0, MAX_STORED_SEARCHES)),
      );
    },
    [recentSearchesParsed, update],
  );

  return useMemo(
    // Expose all recent searches and limit results when the user starts a search.
    // Auto complete may find older stored searches when filtering but by default we'll
    // show MAX_RECENT_SEARCHES.
    () => [filteredRecentSearches, { addRecentSearch }],
    [filteredRecentSearches, addRecentSearch],
  );
}
