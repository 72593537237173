import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Divider from '@/components/switchback/Divider/Divider';
import Dropdown from '@/components/switchback/Dropdown/Dropdown';
import { IVehicle } from '@/services/types/search/vehicle';

import VehicleContent from '../DesktopUniversalSearch/VehicleContent/VehicleContent';
import classes from './MobileUniversalSearchTowingVehicles.module.css';

const CustomHeader: React.FC = () => (
  <>
    <span className="inline-flex items-center items-bottom md:hidden">
      <h2 className="pr-6 text-xl text-gray-900 semiHighlight">
        <FormattedMessage defaultMessage="Add details" id="GykZiH" />
      </h2>
    </span>
    <Divider className="mt-8 mb-4" />
  </>
);

interface IMobileUniversalSearchTowingVehiclesProps {
  onChangeVehicle?: (vehicle?: IVehicle) => void;
  vehicle?: IVehicle;
}

const MobileUniversalSearchTowingVehicles: React.FC<IMobileUniversalSearchTowingVehiclesProps> = ({
  onChangeVehicle,
  vehicle,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalButtonRef = useRef<HTMLButtonElement>(null);

  const handleOpenGuests = () => {
    setIsModalOpen(true);
  };

  const dismiss = () => {
    setIsModalOpen(false);
  };

  const text = `${vehicle?.year || ''} ${vehicle?.make || ''} ${vehicle?.model || ''}`;

  return (
    <div>
      <label
        htmlFor="vehicle-button"
        className="block text-xs font-bold text-gray-500 uppercase semiHighlight">
        <FormattedMessage defaultMessage="Towing vehicle" id="TQZ9ad" />
      </label>
      <button
        id="vehicle-button"
        className={classes.towing_tab}
        ref={modalButtonRef}
        onClick={handleOpenGuests}
        type="button"
        aria-expanded={isModalOpen}>
        {vehicle ? text : <FormattedMessage defaultMessage="Year, make, model" id="gIiWdO" />}
      </button>
      <div id="header-guests-dialog" role="region">
        <Dropdown
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          customHeader={<CustomHeader />}
          show={isModalOpen}
          relativeTo={modalButtonRef.current}
          onDismiss={dismiss}>
          <VehicleContent
            onChangeVehicle={onChangeVehicle}
            open
            vehicle={vehicle}
            onConfirm={dismiss}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default MobileUniversalSearchTowingVehicles;
