import { TPageCategories } from '../pageEventUtils';

export enum EFeedbackEventName {
  FEEDBACK_CTA_SELECTED = 'Feedback CTA Selected',
}

export enum EFeedbackEventSource {
  FOOTER = 'footer',
  HELP_MENU = 'help_menu',
  TODAYS_ACTIVITIES = 'todays_activities',
  BOOKING_DETAILS = 'booking_details',
}

export interface IFeedbackCTASelectedEvent {
  pageCategory: TPageCategories;
  source: EFeedbackEventSource;
}
