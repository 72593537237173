export enum ESearchFilters {
  // CAMPGROUNDS SEARCH FILTERS (used in /external-campgrounds odc-search endpoint)
  CAMPSITE_CATEGORY_TYPES = 'cs[category_types]',
  CAMPSITE_MAX_VEHICLE_LENGTH = 'cs[max_vehicle_length]',
  CAMPSITE_RV_SITE_FEATURES = 'cs[rv_site_features]',
  CAMPSITE_TENT_SITE_FEATURES = 'cs[tent_site_features]',
  CAMPSITE_LODGING_FEATURES = 'cs[lodging_features]',
  CAMPSITE_STATIONARY_CAMPER_SITE_FEATURES = 'cs[stationary_camper_site_features]',
  CAMPGROUND_FEATURES = 'cg[features]',
  CAMPSITE_SUPPORTED_SUBTYPES = 'cs[supported_types]',
  CAMPSITE_BATHROOMS = 'cs[bathrooms]',
  CAMPSITE_BEDROOMS = 'cs[bedrooms]',
  CAMPSITE_BEDS = 'cs[beds]',
  // RV & STAY SEARCH FILTERS (used in /rentals odc-search endpoint)
  GEOIP_ADDRESS = 'geoip_address',
  ADDRESS = 'address',
  AUTO_RADIUS = 'autoRadius',
  BOUNDS_NE = 'bounds[ne]',
  BOUNDS_SW = 'bounds[sw]',
  STAY_CHECK_IN_TYPE = 'stayCheckInType',
  STAY_PARKING_ONSITE = 'stay_parking_onsite',
  CANCEL_POLICY = 'cancelPolicy',
  DATE_FROM = 'date[from]',
  DATE_TO = 'date[to]',
  FLEXIBLE_DAYS = 'flexible_days',
  DELIVERY = 'delivery',
  DELIVERY_QUERY = 'deliveryQuery',
  DELIVERY_ADDRESS = 'deliveryAddress',
  DELIVERY_STATIONARY = 'deliveryStationary',
  DELIVERY_RADIUS = 'deliveryRadius',
  DISTANCE_UNIT = 'distanceUnit',
  FILTER_FEATURE = 'filter[feature]',
  FILTER_KEYWORDS = 'filter[keywords]',
  FILTER_OWNERTYPE = 'filter[ownerType]',
  FILTER_STYLE = 'filter[style]',
  FILTER_TAGS = 'filter[tags]',
  FILTER_TYPE = 'filter[type]',
  FILTER_PROPERTY = 'filter[property]',
  FILTER_VEHICLE_MAKE = 'filter[vehicle_make]',
  FILTER_VEHICLE_MAKE_MODELS = 'filter[vehicle_model]',
  GUESTS_ADULTS = 'sleeps[adults]',
  GUESTS_CHILDREN = 'sleeps[children]',
  GUESTS_INFANTS = 'sleeps[infants]',
  INSTANT_BOOK = 'instantBook',
  INSURANCE_STATE = 'insuranceState',
  LENGTH_BETWEEN = 'length[between]',
  LENGTH_GREATER = 'length[gte]',
  LENGTH_LESSER = 'length[lte]',
  NEAR = 'near',
  NEAR_RENTAL = 'nearRental',
  NEW_OWNERS = 'newOwners',
  ONLY = 'only',
  OWNER_ID = 'ownerId',
  PAGE_LIMIT = 'page[limit]',
  PAGE_OFFSET = 'page[offset]',
  PRICE_MIN = 'price[min]',
  PRICE_MAX = 'price[max]',
  RADIUS = 'radius',
  RENTAL_CATEGORY = 'filter[rental_category]',
  SCORE = 'score',
  SEATBELTS = 'seatbelts',
  SLEEPS = 'sleeps',
  BEDROOMS = 'stay_bedrooms',
  BEDS = 'stay_beds',
  BATHROOMS = 'stay_baths',
  SLEEPS_ADULTS = 'sleeps[adults]',
  SLEEPS_CHILDREN = 'sleeps[children]',
  SLEEPS_INFANTS = 'sleeps[infants]',
  SORT = 'sort',
  UNLIMITED_GENERATOR = 'unlimitedGenerator',
  UNLIMITED_MILES = 'unlimitedMiles',
  WEIGHT_GREATER = 'trailerWeight[gte]',
  WEIGHT_LESSER = 'trailerWeight[lte]',
  YEAR_GREATER = 'year[gte]',
  YEAR_LESSER = 'year[lte]',
  DELIVERY_CENTER = 'deliveryCenter',
  DELIVERY_LOCATION_ID = 'locationId',
  INCLUDE_UNAVAILABLE = 'include_unavailable',
  EXCLUDE_TYPE = 'filter[exclude_type]',
  SUGGESTED = 'suggested',
  TRAILER_WEIGHT = 'trailer_weight',
  SUGGESTED_TOWING_CAPACITY = 'suggested_towing_capacity',
  TOWING_ID = 'towing_id',
  OMIT_AGGREGATION = 'omit_aggregation',
  AUTO_EXPAND_RADIUS = 'auto_expand_radius',
  DELIVERY_DETAILS = 'deliveryDetails',
  IS_ODN = 'cs[is_odn]',
  FULL_DESCRIPTION = 'full_description',
  RAW_JSON = 'raw_json',
  EXPERIMENT = 'experiment',
  SEARCH_DELIVERY_CAMPGROUND_ID = 'delivery_campground_id',
  DELIVERY_CAMPGROUND = 'deliveryCampground',
}

export enum EFilterCategories {
  DRIVABLE = 'Drivable',
  TOWABLE = 'Towable',
  PRICE = 'Price',
  DELIVERY = 'Delivery',
  AMENITIES = 'Amenities',
  ACTIVITIES = 'Activities',
  SCENERY = 'Scenery',
  MORE = 'More',
  SORT = 'Sort',
  TYPE = 'Type',
  PROPERTY = 'Property',
  MAKE = 'Make',
  SITE_TYPE = 'Site Type',
  CAMPGROUND_FEATURES = 'Campground Features',
  // new rv filters - can they go in another enum?
  PET_FRIENDLY = 'Pet Friendly',
  INSTANT_BOOK = 'Instant book',
}

export const DEFAULT_EXCLUSIONS = ['utility-trailer', 'tow-vehicle', 'other'];

// Filters that need to update the map position
export const UPDATE_MAP_FILTERS = [
  ESearchFilters.ADDRESS,
  ESearchFilters.DELIVERY_ADDRESS,
  ESearchFilters.SORT,
];

export const SERP_URLS = [
  '/rv-rental',
  '/stay-rental',
  '/campsite-rental',
  '/campground-rental',
  '/locality',
  '/locality-directory',
  '/locality-directory-by-type',
  '/park-guide',
  '/loadtrip-guide-directory',
  '/park-guide-sub-directory',
  '/event-guide',
  '/event-guide-directory',
  '/event-guide-sub-directory',
  '/landing-page',
  '/loadtrip-guide',
  '/l',
];
