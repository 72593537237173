import { clsx } from 'clsx';
import React, { RefObject } from 'react';

import MapTexture from '@/assets/home/map-texture.png';
import MobileMapTexture from '@/assets/home/mobile-map-texture.png';
import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IHeaderSearchFilterDates, IHeaderSearchFilterGuests } from '@/redux/modules/globalHeader';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { ILodgingCampgrounds } from '@/services/types/search/lodgingCampgrounds';
import { IVehicle } from '@/services/types/search/vehicle';
import { IVehicleCampgrounds } from '@/services/types/search/vehicleCampgrounds';

import DesktopUniversalSearch from './DesktopUniversalSearch/DesktopUniversalSearch';
import UniversalSearchStayBanner from './DesktopUniversalSearch/UniversalSearchStayBanner';
import MobileUniversalSearch from './MobileUniversalSearch/MobileUniversalSearch';
import css from './UniversalSearch.module.css';
import {
  EHeaderTab,
  Header_TOP_MENUS_CAMPGROUNDS,
  IHeaderMenu,
} from './UniversalSearchContainer/menu';
import UniversalSearchContainer from './UniversalSearchContainer/UniversalSearchContainer';

interface UniversalSearchProps {
  onSubmit?: () => void;
  handleRecentSearchNavigation?: (url: string) => void;
  location?: { lat: number; lng: number };
  address?: string;
  guests?: IHeaderSearchFilterGuests;
  dates?: IHeaderSearchFilterDates;
  recentSearches?: IAutocompleteOption[];
  maxRecentSearches: number;
  loading?: boolean;
  showCategory?: boolean;
  showStayFilter?: boolean;
  vehicle?: IVehicle;
  vehicleCampgrounds?: IVehicleCampgrounds;
  lodgingCampgrounds?: ILodgingCampgrounds;
  onChangeAddress?: (address?: string) => void;
  onChangeVehicle?: (vehicle?: IVehicle) => void;
  onChangeVehicleCampgrounds?: (vehicle?: IVehicleCampgrounds) => void;
  onChangeLodgingCampgrounds?: (lodging?: ILodgingCampgrounds) => void;
  onChangeDates: (dates?: IHeaderSearchFilterDates) => void;
  onChangeGuests?: (guests?: IHeaderSearchFilterGuests) => void;
  onConfirmAddress?: (address?: string) => void;
  onConfirmDates?: (dates?: IHeaderSearchFilterDates) => void;
  onConfirmGuests?: (guests: IHeaderSearchFilterGuests) => void;
  className?: string;
  popularDestinations?: { label: string }[];
  openedTab?: IHeaderMenu;
  openHeaderTab: (tab: IHeaderMenu, parentRef: RefObject<HTMLUListElement>) => void;
  openCategoryTab: (tab: IHeaderMenu) => void;
  tabList: IHeaderMenu[];
  hideTabListMenu?: boolean;
  headerTabList: IHeaderMenu[];
  selectedCategory?: IHeaderMenu;
  isGuestOccupancyAvailable?: boolean;
}

const UniversalSearch: React.FC<UniversalSearchProps> = ({
  onSubmit,
  location,
  address,
  dates,
  guests,
  vehicle,
  vehicleCampgrounds,
  lodgingCampgrounds,
  recentSearches = [],
  maxRecentSearches,
  loading = false,
  onChangeAddress,
  onChangeVehicle,
  onChangeVehicleCampgrounds,
  onChangeLodgingCampgrounds,
  onChangeDates,
  onChangeGuests,
  onConfirmAddress,
  onConfirmDates,
  onConfirmGuests,
  className,
  popularDestinations,
  tabList,
  hideTabListMenu = false,
  headerTabList,
  openCategoryTab,
  openedTab,
  openHeaderTab,
  selectedCategory,
  showCategory,
  showStayFilter,
  handleRecentSearchNavigation,
  isGuestOccupancyAvailable,
}) => {
  const { isMobile } = useBreakpoint();
  const isUSMInstantBook = useExperimentIsEnabled(OptimizelyFlags.USM_INSTANT_BOOK);
  const isShowInstantBook = isUSMInstantBook && selectedCategory?.tab === 'rv';
  return (
    <div
      className={clsx({
        [css.fullUniversalSearch as string]: showStayFilter,
        [css.withoutTabListMenu as string]: hideTabListMenu,
      })}
      style={{
        backgroundImage:
          selectedCategory?.tab === EHeaderTab.STAYS
            ? `url('${isMobile ? MobileMapTexture : MapTexture}')`
            : '',
      }}>
      {showStayFilter && !hideTabListMenu && (
        <UniversalSearchContainer
          openedTab={selectedCategory}
          openTab={openCategoryTab}
          menu={tabList}
          isStayMenu
        />
      )}
      {showStayFilter && (
        <div className={selectedCategory?.tab === EHeaderTab.STAYS ? 'visible' : 'invisible h-0'}>
          <UniversalSearchStayBanner />
        </div>
      )}

      {selectedCategory?.tab !== EHeaderTab.STAYS && (
        <>
          {showCategory && (
            <UniversalSearchContainer
              openedTab={openedTab}
              openTab={openHeaderTab}
              secondaryMenu={showStayFilter}
              isShowInstantBook={isShowInstantBook}
              menu={
                selectedCategory?.tab === EHeaderTab.RVS
                  ? headerTabList
                  : Header_TOP_MENUS_CAMPGROUNDS
              }
              className={css.categoryTabs}
            />
          )}
          <div className={`hidden md:block ${className || ''}`}>
            <DesktopUniversalSearch
              location={location}
              address={address}
              dates={dates}
              guests={guests}
              vehicle={vehicle}
              vehicleCampgrounds={vehicleCampgrounds}
              lodgingCampgrounds={lodgingCampgrounds}
              recentSearches={recentSearches}
              maxRecentSearches={maxRecentSearches}
              onSubmit={onSubmit}
              loading={loading}
              onChangeAddress={onChangeAddress}
              onChangeDates={onChangeDates}
              onChangeVehicle={onChangeVehicle}
              onChangeVehicleCampgrounds={onChangeVehicleCampgrounds}
              onChangeLodgingCampgrounds={onChangeLodgingCampgrounds}
              onChangeGuests={onChangeGuests}
              onConfirmAddress={onConfirmAddress}
              onConfirmDates={onConfirmDates}
              onConfirmGuests={onConfirmGuests}
              openedTab={openedTab}
              popularDestinations={popularDestinations}
              isCategoryEnabled={showCategory}
              isStayFilterEnabled={showStayFilter}
              handleRecentSearchNavigation={handleRecentSearchNavigation}
              isGuestOccupancyAvailable={isGuestOccupancyAvailable}
            />
          </div>
          <div className={`w-full md:hidden grid grid-flow-col auto-cols-fr ${className || ''}`}>
            <MobileUniversalSearch
              location={location}
              address={address}
              dates={dates}
              guests={guests}
              vehicle={vehicle}
              vehicleCampgrounds={vehicleCampgrounds}
              lodgingCampgrounds={lodgingCampgrounds}
              recentSearches={recentSearches}
              maxRecentSearches={maxRecentSearches}
              onChangeAddress={onChangeAddress}
              onChangeDates={onChangeDates}
              onChangeVehicle={onChangeVehicle}
              onChangeVehicleCampgrounds={onChangeVehicleCampgrounds}
              onChangeLodgingCampgrounds={onChangeLodgingCampgrounds}
              onChangeGuests={onChangeGuests}
              loading={loading}
              onSubmit={onSubmit}
              openedTab={openedTab}
              popularDestinations={popularDestinations}
              isStayFilterEnabled={showStayFilter}
              handleRecentSearchNavigation={handleRecentSearchNavigation}
              isGuestOccupancyAvailable={isGuestOccupancyAvailable}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UniversalSearch;
