import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IHeaderSearchFilterGuests } from '@/redux/modules/globalHeader';
import { IVehicle } from '@/services/types/search/vehicle';
import {
  IVehicleCampgrounds,
  prettyPrintVehicleCampgrounds,
} from '@/services/types/search/vehicleCampgrounds';

import { getClassName, SEARCH_TAB } from './DesktopUniversalSearch';
import classes from './DesktopUniversalSearch.module.css';

interface IDateTab {
  onClick: () => void;
  selectedTab: SEARCH_TAB | null;
  selectedDates: string | null;
  isTowables: boolean;
  isStayFilterEnabled?: boolean;
}

export const DateTab: React.FC<IDateTab> = ({
  onClick,
  selectedTab,
  selectedDates,
  isTowables,
  isStayFilterEnabled,
}) => (
  <button
    type="button"
    onClick={onClick}
    data-tab={selectedTab}
    className={`${getClassName(selectedTab, SEARCH_TAB.DATES, isStayFilterEnabled)} ${
      classes.homeDateButton
    } ${isTowables ? 'rounded-none' : 'md:rounded-bl-[14px] lg:rounded-bl-none'}`}>
    <span className={classes.homeLabel}>
      <FormattedMessage defaultMessage="Start / End" id="SxGyva" />
    </span>
    {selectedDates ? (
      <span className="text-primary-base">{selectedDates}</span>
    ) : (
      <FormattedMessage defaultMessage="When will you go?" id="NVOwAF" />
    )}
  </button>
);

interface IVehicleDetailTab {
  onClick: () => void;
  selectedTab: SEARCH_TAB | null;
  vehicle?: IVehicle;
  isStayFilterEnabled?: boolean;
}

export const VehicleDetailTab: React.FC<IVehicleDetailTab> = ({
  onClick,
  selectedTab,
  vehicle,
  isStayFilterEnabled,
}) => {
  const text = `${vehicle?.year || ''} ${vehicle?.make || ''} ${vehicle?.model || ''}`;
  return (
    <button
      type="button"
      onClick={onClick}
      data-tab={selectedTab}
      className={`${getClassName(selectedTab, SEARCH_TAB.VEHICLE, isStayFilterEnabled)} ${
        classes.homeVehicleButton
      } rounded-bl-[14px]`}>
      <span className={classes.homeLabel}>
        <FormattedMessage defaultMessage="Towing Capacity" id="ETUws/" />
      </span>
      {vehicle ? (
        <span className="text-primary-base">{text}</span>
      ) : (
        <FormattedMessage defaultMessage="Year, make, model" id="gIiWdO" />
      )}
    </button>
  );
};

interface IVehicleDetailCampgroundsTab {
  onClick: () => void;
  selectedTab: SEARCH_TAB | null;
  vehicle?: IVehicleCampgrounds;
  isStayFilterEnabled?: boolean;
}

export const VehicleDetailCampgroundsTab: React.FC<IVehicleDetailCampgroundsTab> = ({
  onClick,
  selectedTab,
  vehicle,
  isStayFilterEnabled,
}) => {
  const text = prettyPrintVehicleCampgrounds(vehicle);
  return (
    <button
      type="button"
      onClick={onClick}
      data-tab={selectedTab}
      className={`${getClassName(
        selectedTab,
        SEARCH_TAB.VEHICLE_CAMPGROUNDS,
        isStayFilterEnabled,
      )} ${classes.homeVehicleButton} rounded-bl-[14px]`}>
      <span className={classes.homeLabel}>
        <FormattedMessage defaultMessage="Vehicle Filters" id="1KlEM/" />
      </span>
      {text ? (
        <span className="text-primary-base">{text}</span>
      ) : (
        <FormattedMessage defaultMessage="Length & hookups" id="MT69EK" />
      )}
    </button>
  );
};

interface IGuestTabProps {
  onClick: () => void;
  selectedTab: SEARCH_TAB | null;
  guests?: IHeaderSearchFilterGuests;
  isStayFilterEnabled?: boolean;
  isGuestOccupancyAvailable?: boolean;
}

export const GuestsTab: React.FC<IGuestTabProps> = ({
  onClick,
  selectedTab,
  guests,
  isStayFilterEnabled,
  isGuestOccupancyAvailable,
}) => {
  const intl = useIntl();
  const totalGuests =
    (guests?.adults || 0) +
    (guests?.children || 0) +
    (isGuestOccupancyAvailable ? guests?.infants || 0 : 0);

  const buttonLabel = intl.formatMessage(
    {
      defaultMessage: 'Click to {hasValue, select, true {change the} other {add}} guests',
      id: 'PB9EPa',
      description: 'Global Header > Search',
    },
    {
      hasValue: !!totalGuests,
    },
  );
  const placeholder = intl.formatMessage({
    defaultMessage: 'Guests',
    id: 'ogcDqT',
    description: 'Global Header > Placeholder text for header details field',
  });
  const detailsValueText = intl.formatMessage(
    {
      defaultMessage: '{totalGuests, plural, one {# guest} other {# guests}}',
      id: 'YstT2S',
      description: 'Placeholder for header guests field',
    },
    {
      totalGuests,
    },
  );

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${getClassName(selectedTab, SEARCH_TAB.DETAILS, isStayFilterEnabled)} ${
        classes.homeGuestButton
      }`}
      aria-label={buttonLabel}>
      <span className={classes.homeLabel}>{placeholder}</span>
      {!totalGuests ? (
        <FormattedMessage defaultMessage="Who‘s going?" id="Q1dkVt" />
      ) : (
        <span className="text-primary-base">{detailsValueText}</span>
      )}
    </button>
  );
};

interface ISearchButtonTabProps {
  selectedTab: SEARCH_TAB | null;
  loading: boolean;
  isTabActive: boolean;
  isCategoryEnabled?: boolean;
  isStayFilterEnabled?: boolean;
}

export const SearchButton: React.FC<ISearchButtonTabProps> = ({
  selectedTab,
  loading,
  isTabActive,
  isCategoryEnabled,
  isStayFilterEnabled,
}) => {
  const intl = useIntl();

  return (
    <div
      className={`md:self-center ${classes.searchBtnWrapper}
      ${selectedTab && !isTabActive ? 'border-b border-gray-200' : ''}
      ${
        isStayFilterEnabled
          ? selectedTab && !isTabActive
            ? 'rounded-tr-2xl bg-gray-100'
            : 'rounded-tr-2xl bg-white'
          : isTabActive
            ? 'bg-white'
            : 'bg-gray-100'
      }
      ${!selectedTab ? 'rounded-br-[14px]' : ''}
      ${!isCategoryEnabled ? 'lg:rounded-tr-[14px]' : ''}
    `}>
      <Button
        type="submit"
        className={`${classes.search_button} ${classes.homeSearchButton}`}
        loading={loading}
        variant={EButtonColorVariant.Primary}
        label={intl.formatMessage({ defaultMessage: 'Search', id: 'xmcVZ0' })}
      />
    </div>
  );
};
