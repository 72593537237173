import {
  Button,
  EButtonColorVariant,
  EHeadingStyleVariant,
  ELinkVariant,
  Heading,
  Link,
  Modal,
} from '@outdoorsyco/bonfire';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import DatePicker from '@/components/switchback/DatePicker/DatePicker';
import { IHeaderSearchFilterDates } from '@/redux/modules/globalHeader';
import { formatDateFullRange } from '@/utility/format-date';

import FlexibleDateSelectButton from '../DesktopNavbarSearch/DateContent/FlexibleDateSelectButton';

type TMobileSearchDatesModalContentProps = {
  onCloseHandler: () => void;
  dates?: IHeaderSearchFilterDates;
  onChangeDates?: (dates?: IHeaderSearchFilterDates) => void;
};

interface IDates {
  from?: Date;
  to?: Date;
  flexible_days?: number;
}

export const MobileSearchDatesModalContent = ({
  onCloseHandler,
  dates,
  onChangeDates,
}: TMobileSearchDatesModalContentProps) => {
  const intl = useIntl();
  const [selectedDates, setSelectedDates] = useState<IDates>({
    from: dates?.from,
    to: dates?.to,
    flexible_days: dates?.flexible_days,
  });

  const formattedDates = useMemo<string>(() => {
    if (!selectedDates?.from || !selectedDates?.to) {
      return '';
    }

    return formatDateFullRange(selectedDates.from.toString(), selectedDates.to.toString()) || '';
  }, [selectedDates]);

  const handleConfirmDates = () => {
    if (selectedDates.from && !selectedDates.to) {
      return;
    }

    onChangeDates?.(selectedDates);
    onCloseHandler();
  };

  const handleChangeFlexibleDays = (flexible_days?: number) => {
    setSelectedDates({ ...selectedDates, flexible_days });
  };

  const addDatesMessage = intl.formatMessage({ defaultMessage: 'Add dates', id: 'Fzp8xF' });
  const dateText = `${formattedDates}${
    selectedDates?.flexible_days ? ` (±${selectedDates?.flexible_days})` : ''
  }`;

  return (
    <>
      <Modal.Actions className="top-4" />
      <Modal.Header inlineHeader={true} className="flex gap-4 !p-4 !pt-[10px]">
        <Heading variant={EHeadingStyleVariant.H6}>
          {selectedDates.from && selectedDates.to ? dateText : addDatesMessage}
        </Heading>
        {selectedDates.from && selectedDates.to && (
          <Link
            className="z-[1400]"
            label={intl.formatMessage({ defaultMessage: 'Clear dates', id: 'zyDnZa' })}
            variant={ELinkVariant.Underline}
            onClick={() => setSelectedDates({})}
          />
        )}
      </Modal.Header>
      <Modal.Divider />
      <Modal.Content className="mt-2">
        <div>
          <DatePicker
            range
            numberOfMonths={6}
            isStacked={true}
            onChange={({ from, to }: IDates) => setSelectedDates({ ...selectedDates, from, to })}
            dateTo={selectedDates.to}
            dateFrom={selectedDates.from}
            toMonth={dayjs().add(2, 'year').toDate()}
          />
        </div>
      </Modal.Content>
      <Modal.Footer className="p-0">
        <div className="w-full shadow-500">
          <div className="flex p-4">
            <FlexibleDateSelectButton
              selected={selectedDates?.flexible_days === undefined}
              onClick={() => handleChangeFlexibleDays(undefined)}
              label={intl.formatMessage({ defaultMessage: 'Match exact dates', id: 'uhcpp1' })}
            />
            <FlexibleDateSelectButton
              className="mx-4"
              selected={selectedDates?.flexible_days == 1}
              onClick={() => handleChangeFlexibleDays(1)}
              label={intl.formatMessage({ defaultMessage: '±1 day', id: 'Y9uTsx' })}
            />
            <FlexibleDateSelectButton
              selected={selectedDates?.flexible_days == 3}
              onClick={() => handleChangeFlexibleDays(3)}
              label={intl.formatMessage({ defaultMessage: '±3 days', id: '1sJT3M' })}
            />
          </div>
          <div className="w-full border-t border-gray-250">
            <div className="w-full p-4">
              <Button
                className="w-full"
                variant={EButtonColorVariant.Primary}
                label={intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })}
                onClick={handleConfirmDates}
                disabled={selectedDates.from && !selectedDates.to}
              />
            </div>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};
