import { createSelector } from 'reselect';

import { IProps as IPriceProps } from '@/components/switchback/Price/Price';
import { TRootState } from '@/redux/rootReducer';
import { getCurrency } from '@/redux/selectors/currency';
import { getFromAndTo } from '@/redux/selectors/queryParams';
import { IWaiverSummary } from '@/services/types/core/quotes';
import { formatCurrency } from '@/utility/currency';
import { isSSR } from '@/utility/isSSR';
import { mapRentalToPrice } from '@/utility/mapSearchResultToTile';
import { IOutdoorsySessionCookie } from '@/utility/session';

import { getListingPresentmentCurrency, getListingSocialImage, PriceType } from './page';

type TQuoteData = TRootState['quote']['data'];
type TListingData = TRootState['listing']['data'];

export interface IBillSummary {
  addonsValue?: string;
  addonsQuantity?: number;
  feesValue: string;
  rawFeesValue?: number;
  deliveryValue?: string;
  insuranceValue?: string;
  insuranceBundleId?: string;
  duration: number;
  totalPerDay: string;
  totalValue: string;
  rawTotalValue?: string;
  credit?: string;
  discount?: {
    code: string;
    amount: string;
  };
  isCustomInsurance: boolean;
  ownerName?: string;
  tripInsurance?: string;
  damageProtection?: string;
  waiverSummary?: IWaiverSummary;
  dataOrigin?: 'booking' | 'quote';
}

export interface IQuoteEventData {
  event: string;
  rental_id?: number;
  product_id?: number;
  sku?: number;
  category?: string;
  name?: string;
  sleeps?: number;
  price: number;
  price_per_night: number;
  total: number;
  url?: string;
  image_url?: string;
  from?: string;
  to?: string;
  instant_book?: boolean;
}

export const getQuoteFromAndTo = (state: TRootState) => {
  if (!state.quote.data) {
    return {};
  }

  return {
    from: state.quote.data?.from,
    to: state.quote.data?.to,
  };
};

export const getQuoteInput = (state: TRootState) => {
  const currency = getCurrency(state);
  const prevQuote = state.quote.data;
  const discountCode =
    prevQuote?.discount_code || state.cookies.discountCode || state.queryParams.discount_code;
  const rentalId = state.listing.data?.id;
  const rentalItems = state.listing.data?.items;
  const authToken = (state.cookies['outdoorsy-session'] as IOutdoorsySessionCookie)?.authenticated
    ?.token;

  const items = rentalItems
    ?.filter(item => item.count)
    .map(({ id, count }) => ({
      id,
      count,
    }));

  return {
    currency,
    discountCode,
    rentalId,
    items,
    authToken,
  };
};

export const getTotal = createSelector<
  TRootState,
  TQuoteData,
  ReturnType<typeof getCurrency>,
  string
>(
  state => state.quote.data,
  getCurrency,
  (quote, currency) =>
    formatCurrency({
      priceInCents: quote ? quote.total - (quote?.renter_credits_applied ?? 0) : 0,
      currency,
      digits: 2,
    }),
);

type ISiblingPriceProps = IPriceProps;

export interface IPricingInformation extends IPriceProps {
  from?: string;
  to?: string;
  sibling?: ISiblingPriceProps;
}

export const calcDiscount = (originalPrice: number, currentPrice: number) =>
  Math.round(((originalPrice && 1 - currentPrice / originalPrice) || 0 + Number.EPSILON) * 100) ||
  0;

export const getPricingInformation = createSelector<
  TRootState,
  TQuoteData,
  TListingData | undefined,
  ReturnType<typeof getListingPresentmentCurrency>,
  IPricingInformation
>(
  state => state.quote.data,
  state => state.listing.data,
  getListingPresentmentCurrency,
  (quote, listing, listingCurrency) => {
    if (!quote) {
      const price = mapRentalToPrice({ rental: listing });
      return {
        currency: listingCurrency,
        ...price,
      } as IPricingInformation;
    }

    const instantBook = quote.instant_book && quote.is_request_less_than_minimum_days !== true;
    const { from, to, presentment_currency } = quote;
    const originalPrice = quote.original_day_price;
    const currentPrice = quote.calculated_day_price;
    const overrideDayPrice = quote.original_day_override_price || originalPrice;
    const discount = calcDiscount(originalPrice, currentPrice);
    const discountFromOverrideDayPrice = calcDiscount(overrideDayPrice, currentPrice);
    const sibling = quote.siblings?.rental_campsite;
    const siblingCurrentPrice = sibling?.calculated_day_price;
    const siblingOriginalPrice = sibling?.original_day_price;
    const siblingOverrideDayPrice =
      sibling?.original_day_override_price || sibling?.original_day_price;
    const siblingDiscountFromOverrideDayPrice =
      siblingOverrideDayPrice && siblingCurrentPrice
        ? calcDiscount(siblingOverrideDayPrice, siblingCurrentPrice)
        : 0;

    return {
      currency: presentment_currency,
      instantBook,
      currentPrice,
      originalPrice,
      overrideDayPrice,
      discount,
      discountFromOverrideDayPrice,
      period: listing?.active_options?.use_day_pricing ? PriceType.DAY : PriceType.NIGHT,
      from,
      to,
      ...(sibling && {
        sibling: {
          currency: presentment_currency,
          period: PriceType.NIGHT,
          instant_book: sibling?.instant_book,
          currentPrice: siblingCurrentPrice,
          original_price: siblingOriginalPrice,
          overrideDayPrice: siblingOverrideDayPrice,
          discountFromOverrideDayPrice: siblingDiscountFromOverrideDayPrice,
        },
      }),
    } as IPricingInformation;
  },
);

export const getCheckoutURL = createSelector<
  TRootState,
  number | undefined,
  string | undefined,
  string
>(
  state => state.listing.data?.id,
  state => state.quote.data?.id,
  (rentalId, quoteId) => `/checkout/${rentalId ?? ''}/new?quote=${quoteId ?? ''}`,
);

export const getIsInstantBook = (state: TRootState) =>
  Boolean(
    (state.listing.data?.instant_book || state.checkout.booking?.instant_book) &&
      state.quote.data?.is_request_less_than_minimum_days !== true,
  );

export const getIsRequestLessThanMinimumDays = (state: TRootState) =>
  Boolean(state.quote.data?.is_request_less_than_minimum_days === true);

export const getOwnerMinDays = (state: TRootState) =>
  Number(state.quote.data?.dealer_minimum_days || 0);

export const getDiscountMessage = (state: TRootState) =>
  state.quote.data?.discount_message?.message;

export const getQuoteDuration = (state: TRootState) => state.quote.data?.duration;

export const getQuoteEventData = createSelector<
  TRootState,
  TListingData,
  TQuoteData,
  ReturnType<typeof getFromAndTo>,
  ReturnType<typeof getListingSocialImage>,
  IQuoteEventData
>(
  state => state.listing.data,
  state => state.quote.data,
  getFromAndTo,
  getListingSocialImage,
  (listing, quote, selectedDates, image) => {
    let rental_category = 'rv';
    if (listing) {
      if (listing.rental_category === 'stay') {
        rental_category = 'stay';
      }
    } else if (quote) {
      if (quote.rental_summary?.stay_summary) {
        rental_category = 'stay';
      }
    }

    return {
      event: 'Quote loaded',
      rental_category,
      rental_id: listing?.id,
      product_id: listing?.id,
      sku: listing?.id,
      category: listing?.type,
      name: listing?.name,
      sleeps: listing?.sleeps,
      price: (quote?.original_day_price || 0) / 100,
      price_per_night: (quote?.calculated_day_price || 0) / 100,
      total: ((quote?.total_after_credits ?? quote?.total) || 0) / 100,
      url: !isSSR() ? window.location.href : undefined,
      image_url: image,
      from: selectedDates.from || quote?.from,
      to: selectedDates.to || quote?.to,
      instant_book: listing?.instant_book && quote?.is_request_less_than_minimum_days !== true,
    };
  },
);

export const getQuoteOriginalDayPrice = createSelector<TRootState, TQuoteData, number | undefined>(
  state => state.quote.data,
  quote => (quote?.original_day_price || 0) / 100,
);

export const getQuoteDiscountPercentOff = createSelector<
  TRootState,
  TQuoteData,
  number | undefined
>(
  state => state.quote.data,
  quote => {
    const originalPrice = quote?.original_day_price || 0;
    const currentPrice = quote?.calculated_day_price || 0;
    const discount =
      Math.round(
        ((originalPrice && 1 - currentPrice / originalPrice) || 0 + Number.EPSILON) * 100,
      ) || 0;
    return discount;
  },
);
