import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '@/components/switchback/Link/Link';
import { useMyAccountMenuItems } from '@/constants/menu';
import { trackNavClickedEvent } from '@/services/analytics/header';

interface IProps {
  hasUnreadMessages?: boolean;
  hasPendingReviews?: boolean;
  isOwner?: boolean;
  userName?: string;
  onClickSignout: () => void;
  onClickItem?: () => void;
}

const GlobalHeaderNavMyAccount: React.FC<IProps> = ({
  hasUnreadMessages,
  // hasPendingReviews,
  isOwner,
  userName,
  onClickSignout,
  onClickItem,
}) => {
  const items = useMyAccountMenuItems();
  const linkClassName = 'flex items-center py-3 text-gray-800 autoType300 w-full';

  const handleOnClick = (link: string) => {
    trackNavClickedEvent({
      clickedLink: link,
      isInDropdown: true,
      dropdownHeader: 'My Account',
    });
  };

  return (
    <div className="lg:min-w-[16rem]">
      <div className="pb-6 mb-3 font-bold text-gray-900 border-b border-gray-310 lg:pt-0 autoType500">
        {userName ? (
          <FormattedMessage
            defaultMessage="Hi, {name}"
            id="IjcYm5"
            description="Global Header > My Account"
            values={{ name: userName }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Hi"
            id="Sd5efi"
            description="Global Header > My Account"
            values={{ name: userName }}
          />
        )}
      </div>

      <ul>
        {items.map((item, index) => {
          const isMessages = item.href === '/messages';
          // const isUpcomingTrips = item.href === '/trips';

          const hasNotifications = isMessages && hasUnreadMessages;

          return (
            <li key={index}>
              <Link legacy href={item.href}>
                <a
                  className={linkClassName}
                  onClick={() => {
                    onClickItem?.();
                    handleOnClick(item.label);
                  }}>
                  {item.label}
                  {hasNotifications && <div className="w-2 h-2 ml-1 bg-green-500 rounded" />}
                </a>
              </Link>
            </li>
          );
        })}

        <li className="pt-4 mt-4 border-t border-gray-310">
          {isOwner ? (
            <a
              href="/dashboard"
              className={linkClassName}
              onClick={() => {
                onClickItem?.();
                handleOnClick('Dashboard');
              }}>
              <FormattedMessage
                defaultMessage="Switch to hosting"
                id="2dLHvx"
                description="Global Header > My Account"
              />
            </a>
          ) : (
            <a
              href="/become-a-host"
              className={linkClassName}
              onClick={() => {
                onClickItem?.();
                handleOnClick('List my RV');
              }}>
              <FormattedMessage
                defaultMessage="Become a host"
                id="1Cch+Q"
                description="Global Header > My Account"
              />
            </a>
          )}
        </li>

        <li>
          <button
            type="button"
            className={linkClassName}
            onClick={() => {
              handleOnClick('Log out');
              onClickSignout();
            }}>
            <FormattedMessage
              defaultMessage="Log out"
              id="Xne/lE"
              description="Global Header > My Account"
            />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default GlobalHeaderNavMyAccount;
