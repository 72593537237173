import { defineMessage } from 'react-intl';

import { UNITS } from '@/constants/units';

import { getIntl } from './i18n';
const KM_MILES = 0.62137;

type TMeasurementMessage = { id: string; defaultMessage: string };

const message: TMeasurementMessage = defineMessage({
  id: 'Ol2MC7',
  defaultMessage: `{display} {long, select,
      true {{unit, select,
        foot {{count, plural, one {foot} other {feet}}}
        meter {{count, plural, one {meter} other {meters}}}
        kilometer {{count, plural, one {kilometer} other {kilometers}}}
        mile {{count, plural, one {mile} other {miles}}}
        gallon {{count, plural, one {gallon} other {gallons}}}
        liter {{count, plural, one {liter} other {liters}}}
        kilogram {{count, plural, one {kilogram} other {kilograms}}}
        pound {{count, plural, one {pound} other {pounds}}}
        other {{unit}}
      }}
      other {{unit, select,
        foot {ft}
        meter {m}
        kilometer {km}
        mile {mi}
        gallon {gal}
        liter {L}
        kilogram {kg}
        pound {{count, plural, one {lb} other {lbs}}}
        other {{unit}}
      }}
    }`,
});

export const formatMeasurement = (
  value: number,
  {
    unit,
    long = false,
  }: {
    unit: UNITS;
    long?: boolean;
  },
): string => {
  const intl = getIntl();
  return intl.formatMessage(message, {
    count: Math.floor(value),
    display: intl.formatNumber(value, { unit }),
    unit,
    long: String(long),
  });
};

export const kmToMiles = (km: number) => km * KM_MILES;
