import { ETextStyleVariant, Icon, Text } from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useRouter } from '@/hooks/useRouter';
import { EVehicleType } from '@/redux/modules/globalHeader';
import {
  getHeaderSearchAddressFilter,
  getHeaderSearchDatesFilter,
  getHeaderSearchGuestsFilter,
  getHeaderSearchVehicleTypeFilter,
} from '@/redux/selectors/globalHeader';
import { formatDateRange } from '@/utility/format-date';
import { yieldToMain } from '@/utility/yieldToMain';

import { useApplySearch } from './applySearch';
import { DateFilterContent } from './DateFilterContent';
import { GuestsFilterContent } from './GuestsFilterContent';
import { LocationFilterContent } from './LocationFilterContent';
import { LocationFilterProvider } from './LocationFilterContext';
import { VehicleTypeFilterContent } from './VehicleTypeFilterContent';

enum EHomeSearchWidgetFilter {
  Location = 'Location',
  Date = 'Date',
  Guests = 'Guests',
  VehicleType = 'Vehicle Type',
}

export enum EMobileHomeSearchWidgetVariant {
  Default = 'Default',
  Header = 'Header',
}

type TMobileHomeSearchWidgetProps = {
  variant?: EMobileHomeSearchWidgetVariant;
};

export const MobileHomeSearchWidget = ({
  variant = EMobileHomeSearchWidgetVariant.Default,
}: TMobileHomeSearchWidgetProps) => {
  const intl = useIntl();
  const router = useRouter();

  const [openedFilter, setOpenedFilter] = useState<EHomeSearchWidgetFilter>();

  const setOpenedFilterYTM = (filter?: EHomeSearchWidgetFilter) => {
    yieldToMain(() => setOpenedFilter(filter));
  };

  const [, { update: updatePrevBaseUrl }] = useLocalStorage('prevBaseUrl');
  const applySearch = useApplySearch();

  const handleSearch = async () => {
    setOpenedFilter(undefined);
    updatePrevBaseUrl(router.route);
    await applySearch();
  };

  const addressSelection = useAddressSelection();
  const datesSelection = useDatesSelection();
  const guestsSelection = useGuestsSelection();
  const vehicleTypeSelection = useVehicleTypeSelection();

  // Open the next filter in the sequence that is not filled
  const openNextFilter = () => {
    if (!addressSelection) return setOpenedFilterYTM(EHomeSearchWidgetFilter.Location);
    if (!datesSelection) return setOpenedFilterYTM(EHomeSearchWidgetFilter.Date);
    if (!guestsSelection) return setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests);
    return setOpenedFilterYTM(EHomeSearchWidgetFilter.VehicleType);
  };

  const selection = (
    <div className="flex flex-col w-full text-left">
      <Text variant={ETextStyleVariant.SmallBold} component="span" className="truncate">
        {addressSelection ||
          intl.formatMessage({
            defaultMessage: 'Where are you heading?',
            id: 'JMeoOx',
            description: 'Home Search Widget > Mobile Location Filter Label',
          })}
      </Text>

      <Text variant={ETextStyleVariant.LegalRegular} component="span" className="truncate">
        {[
          datesSelection ||
            intl.formatMessage({
              defaultMessage: 'Add dates',
              id: 'rBFwhW',
              description: 'Home Search Widget > Mobile Dates Filter Label',
            }),
          guestsSelection ||
            intl.formatMessage({
              defaultMessage: 'Add guests',
              id: 'xtofXr',
              description: 'Home Search Widget > Mobile Guests Filter Label',
            }),
          vehicleTypeSelection ||
            intl.formatMessage({
              defaultMessage: 'Add vehicle type',
              id: 'b5YF9r',
              description: 'Home Search Widget > Mobile Vehicle Type Filter Label',
            }),
        ].join(' • ')}
      </Text>
    </div>
  );

  return (
    <>
      {variant === EMobileHomeSearchWidgetVariant.Default && (
        <div id="default-mobile-home-search-widget" className="w-full max-w-md">
          <div className="flex w-full bg-white border rounded-full border-neutral-20">
            <button onClick={openNextFilter} className="p-1 pl-4 overflow-hidden grow">
              {selection}
            </button>

            <button
              onClick={handleSearch}
              className="flex items-center justify-center w-12 h-12 m-1 ml-4 text-white rounded-full bg-bark-30 shrink-0">
              <Icon name="General.Search" width={24} height={24} />
            </button>
          </div>
        </div>
      )}

      {variant === EMobileHomeSearchWidgetVariant.Header && (
        <button
          onClick={openNextFilter}
          className="flex items-center w-full px-4 py-1 overflow-hidden bg-white border rounded-full gap-2 border-neutral-20">
          <Icon name="General.Search" width={16} height={16} />
          {selection}
        </button>
      )}

      <LocationFilterProvider onNavigateToRecentSearch={() => setOpenedFilter(undefined)}>
        <LocationFilterContent
          onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
          nextButtonLabel={intl.formatMessage({
            defaultMessage: 'Next: enter dates',
            id: 'fUbsaE',
            description: 'Home Search Widget > Next to dates',
          })}
          isOpened={openedFilter === EHomeSearchWidgetFilter.Location}
          onClose={() => setOpenedFilterYTM(undefined)}
          onDataSelection={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
        />
      </LocationFilterProvider>

      <DateFilterContent
        onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Location)}
        onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests)}
        nextButtonLabel={intl.formatMessage({
          defaultMessage: 'Next: guest details',
          id: 'bOq8a+',
          description: 'Home Search Widget > Next to guests',
        })}
        isOpened={openedFilter === EHomeSearchWidgetFilter.Date}
        onClose={() => setOpenedFilterYTM(undefined)}
      />

      <GuestsFilterContent
        onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
        onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.VehicleType)}
        nextButtonLabel={intl.formatMessage({
          defaultMessage: 'Next: vehicle type',
          id: 'OJgPFt',
          description: 'Home Search Widget > Next to vehicle type',
        })}
        isOpened={openedFilter === EHomeSearchWidgetFilter.Guests}
        onClose={() => setOpenedFilterYTM(undefined)}
      />

      <VehicleTypeFilterContent
        onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests)}
        onSearch={handleSearch}
        isOpened={openedFilter === EHomeSearchWidgetFilter.VehicleType}
        onClose={() => setOpenedFilterYTM(undefined)}
      />
    </>
  );
};

const useAddressSelection = () => {
  return useSelector(getHeaderSearchAddressFilter);
};

const useDatesSelection = () => {
  const dates = useSelector(getHeaderSearchDatesFilter);

  if (dates?.from && dates?.to) {
    return `${formatDateRange(dates.from.toString(), dates.to.toString(), false)} ${
      dates?.flexible_days ? ` (±${dates?.flexible_days})` : ''
    }`;
  }
};

const useGuestsSelection = () => {
  const intl = useIntl();

  const guests = useSelector(getHeaderSearchGuestsFilter);
  const totalGuests = (guests?.adults || 0) + (guests?.children || 0);

  if (totalGuests) {
    return intl.formatMessage(
      {
        defaultMessage: '{totalGuests, plural, one {# guest} other {# guests}}',
        id: '5Y37yc',
        description: 'Home Search Widget > Guests Filter Selected Value',
      },
      {
        totalGuests,
      },
    );
  }
};

const useVehicleTypeSelection = () => {
  const intl = useIntl();

  const vehicleType = useSelector(getHeaderSearchVehicleTypeFilter);

  if (vehicleType === EVehicleType.DRIVABLE) {
    return intl.formatMessage({
      defaultMessage: 'Drive',
      id: 'lvCPt0',
      description: 'Home Search Widget > Vehicle Type Filter Drive Selection',
    });
  }

  if (vehicleType === EVehicleType.TOWABLE) {
    return intl.formatMessage({
      defaultMessage: 'Tow',
      id: '7oFaAQ',
      description: 'Home Search Widget > Vehicle Type Filter Tow Selection',
    });
  }

  if (vehicleType === EVehicleType.DELIVERABLES) {
    return intl.formatMessage({
      defaultMessage: 'Delivery',
      id: 'Ued5BM',
      description: 'Home Search Widget > Vehicle Type Filter Delivery Selection',
    });
  }
};
