import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Divider from '@/components/switchback/Divider/Divider';
import Text from '@/components/switchback/Text/Text';

import GuestDetailItem from './GuestDetailItem';

interface IGuestsMinMaxValues {
  adults: { min: number; max: number };
  children: { min?: number; max: number };
  infants: { min?: number; max: number };
}

const DEFAULT_GUESTS_MIN_MAX_VALUES: IGuestsMinMaxValues = {
  adults: { min: 0, max: 10 },
  children: { min: 0, max: 10 },
  infants: { min: 0, max: 10 },
};

interface IGuestDetailProps {
  adultsValue: number;
  childrenValue: number;
  infantsValue: number;
  onChangeAdults: (value: number) => void;
  onChangeChildren: (value: number) => void;
  onChangeInfants: (value: number) => void;
  isGuestOccupancyAvailable?: boolean;
  guestOccupancyMinMaxValues?: IGuestsMinMaxValues;
}

const GuestDetail: React.FC<IGuestDetailProps> = ({
  adultsValue,
  childrenValue,
  infantsValue,
  onChangeAdults,
  onChangeChildren,
  onChangeInfants,
  isGuestOccupancyAvailable,
  guestOccupancyMinMaxValues = DEFAULT_GUESTS_MIN_MAX_VALUES,
}) => {
  const intl = useIntl();
  const labels = {
    adults: {
      label: intl.formatMessage({
        description: 'Guests filter > Adult QuantitySelector title',
        defaultMessage: 'Adults',
        id: 'og/mwU',
      }),
      disclaimer: intl.formatMessage({
        description: 'Guests filter > Adult QuantitySelector disclaimer',
        defaultMessage: 'Ages 13 and up',
        id: 'Pzicwm',
      }),
    },
    children: {
      label: intl.formatMessage({
        description: 'Guests filter > Children QuantitySelector title',
        defaultMessage: 'Children',
        id: 'f7swIl',
      }),
      disclaimer: isGuestOccupancyAvailable
        ? intl.formatMessage({
            description: 'Guests filter > Children QuantitySelector disclaimer',
            defaultMessage: 'Ages 3-12',
            id: 'JW18E+',
          })
        : intl.formatMessage({
            description: 'Guests filter > Children QuantitySelector disclaimer',
            defaultMessage: 'Ages 12 and under',
            id: '69YmOF',
          }),
    },
    infants: {
      label: intl.formatMessage({
        description: 'Guests filter > Infants QuantitySelector title',
        defaultMessage: 'Infants',
        id: '4sPQXv',
      }),
      disclaimer: intl.formatMessage({
        description: 'Guests filter > Infants QuantitySelector disclaimer',
        defaultMessage: 'Ages 2 or under',
        id: '0ipV9p',
      }),
    },
  };

  return (
    <div className="grow">
      {!isGuestOccupancyAvailable && (
        <Text
          type="paragraph"
          className="mb-5 text-xs font-bold text-gray-500 uppercase semiHighlight">
          <FormattedMessage defaultMessage="guests" id="83vj7t" />
        </Text>
      )}
      <GuestDetailItem
        label={labels.adults.label}
        guestsCount={adultsValue}
        onChange={onChangeAdults}
        disclaimer={labels.adults.disclaimer}
        minQuantity={guestOccupancyMinMaxValues.adults.min}
        maxQuantity={guestOccupancyMinMaxValues.adults.max}
      />
      <Divider className="my-4" />
      <GuestDetailItem
        label={labels.children.label}
        guestsCount={childrenValue}
        onChange={onChangeChildren}
        disclaimer={labels.children.disclaimer}
        maxQuantity={guestOccupancyMinMaxValues.children.max}
      />
      {isGuestOccupancyAvailable && (
        <>
          <Divider className="my-4" />
          <GuestDetailItem
            label={labels.infants.label}
            guestsCount={infantsValue}
            onChange={onChangeInfants}
            disclaimer={labels.infants.disclaimer}
            maxQuantity={guestOccupancyMinMaxValues.infants.max}
          />
        </>
      )}
    </div>
  );
};

export default GuestDetail;
