import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useLocaleSettings } from '@/hooks/useLocaleSettings';

const ReCaptchaLegalNotice: React.FC = () => {
  const { locale } = useLocaleSettings();

  return (
    <p className="text-sm space-y-6">
      <FormattedMessage
        defaultMessage="This site is protected by reCAPTCHA and the Google <PrivacyPolicy>Privacy Policy</PrivacyPolicy> and <TermsOfService>Terms of Service</TermsOfService> apply."
        id="jASHoz"
        values={{
          PrivacyPolicy: function PrivacyPolicy(text: string) {
            return (
              <a
                className="underline hover:no-underline"
                target="_blank"
                rel="noreferrer"
                href={`https://policies.google.com/privacy?hl=${locale}`}>
                {text}
              </a>
            );
          },
          TermsOfService: function TermsOfService(text: string) {
            return (
              <a
                className="underline hover:no-underline"
                target="_blank"
                rel="noreferrer"
                href={`https://policies.google.com/terms?hl=${locale}`}>
                {text}
              </a>
            );
          },
        }}
      />
    </p>
  );
};

export default ReCaptchaLegalNotice;
