import { isSSR } from '@/utility/isSSR';

export const FAQ_HELP_PAGE = '/help';
export const HOW_IT_WORKS_PAGE = '/how-it-works';
export const FORGOT_PAGE = '/users/new?method=recover';
export const LOGIN_PAGE = '/users/new?method=login';
export const PHOTOS = '/photos';
export const REVIEWS = '/reviews';
export const SEARCH_API_QUERY_PARAMS =
  '?raw_json=true&seo_links=true&education=true&average_daily_pricing=true';
export const ROADSIDE_ASSISTANCE = '/roadside-assistance';
export const SHARE_URL_FACEBOOK = 'https://www.facebook.com/sharer/sharer.php?u={{url}}';
export const SHARE_URL_MAIL = 'mailto:?subject={{title}}&body={{description}}';
export const SHARE_URL_PINTEREST =
  'http://pinterest.com/pin/create/button/?url={{url}}&description={{title}}&media={{image}}';
export const SHARE_URL_TWITTER =
  'https://twitter.com/intent/tweet?text={{title}}&url={{url}}&via=outdoorsy';
export const SIGN_UP_PAGE = '/users/new?method=signup';
export const TOS_PAGE = '/help/outdoorsy-terms-and-conditions';
export const RV_SEARCH_PAGE = '/rv-search';
export const TRIPS_PAGE = '/trips';
export const PERSONA_SDK_URL = 'https://cdn.withpersona.com/dist/persona-v4.5.0.js';
export const CLOUDINARY_API_URL = 'https://api.cloudinary.com/v1_1/outdoorsy/image/upload';
export const WP_API_HOST =
  typeof isSSR() &&
  !process.env.CI &&
  ['staging', 'production'].includes(process.env.deployTarget as string)
    ? process.env.internalWpApiHost
    : process.env.wpApiHost;
export const ARTICLE_CATEGORY_URL = `${WP_API_HOST}/wp/v2/article?article-category=`;
export const ARTICLE_SUBCATEGORY_URL = `${WP_API_HOST}/wp/v2/article-category/`;
export const HELP_SEARCH_URL = `${WP_API_HOST}/wp/v2/article?search=`;
export const ARTICLE_TAG_URL = `${WP_API_HOST}/wp/v2/article-tag?post=`;
export const ARTICLE_CATEGORY_POST_URL = `${WP_API_HOST}/wp/v2/article-category?post=`;
export const HELP_CATEGORIES_URL = `${WP_API_HOST}/wp/v2/article-category?per_page=22`;
export const ARTICLE_URL = `${WP_API_HOST}/wp/v2/article`;
export const ARTICLE_SLUG_URL = `${WP_API_HOST}/wp/v2/article?slug=`;
export const HELP_ARTICLE_CATEGORY_PAGE = `${WP_API_HOST}/wp/v2/article?per_page=100&article-category=`;
export const HELP_ARTICLE_TAG_PAGE = `${WP_API_HOST}/wp/v2/article?per_page=100&article-tag=`;

export const AFFILIATE_HAS_OFFERS = 'http://outdoorsyinc.hasoffers.com/signup';
export const AFFILIATE_AVANT_LINK = 'https://www.avantlink.com/signup/affiliate/us?merchant=15421';
export const REFERRAL_STATS = '/referrals/stats';
export const SEND_REFERRALS = '/referrals/refer';
export const CLOUDSPONGE_API = 'https://api.cloudsponge.com/widget/';
export const HELP_HERO_URL = `https://res.cloudinary.com/outdoorsy/image/upload/v1623183511/corex/help/united-states-rv-rental-sf.png`;
export const APP_DOWNLOAD = `/app-download`;
export const RENTER_IOS_LINK = `https://apps.apple.com/app/apple-store/id1443835927?pt=118352810&ct=product-banner&mt=8`;
export const RENTER_ANDROID_LINK = `https://play.google.com/store/apps/details?id=com.outdoorsy.renter&referrer=utm_source%3Doutdoorsy%26utm_campaign%3Dproduct_banner`;
export const OWNER_IOS_LINK = `https://apps.apple.com/app/apple-store/id1416795287?pt=118352810&ct=product-banner&mt=8`;
export const OWNER_ANDROID_LINK = `https://play.google.com/store/apps/details?id=com.outdoorsy.owner&referrer=utm_source%3Doutdoorsy%26utm_campaign%3Dproduct_banner`;
export const SAVED_RENTALS = `/rentals?favorite=true&full=true`;
export const RESET_PASSWORD = `/auth/creds/reset`;
export const TRAVEL_CREDITS = `/travel-credits`;
export const CLAIM_GIFT_CARD = `/gift-cards/claim`;
export const EARNED_CREDITS = `/stats/earned-credits`;
export const HUBSPOT_FORM = 'https://api.hsforms.com/submissions/v3/integration/submit/2628911';
export const SIGN_UP_EMAIL = '/email/signup';
