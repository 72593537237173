import Link from 'next/link';
import React, { MouseEvent } from 'react';
import url, { UrlObject } from 'url';

import { useRouter } from '@/hooks/useRouter';
import { addUTMParams } from '@/utility/queryParams';

import Anchor from '../Anchor/Anchor';

type Url = string | UrlObject;

import css from './Link.module.css';

export enum LinkVariants {
  noUnderlineHover = 'noUnderlineHover',
  noUnderline = 'noUnderline',
  underlineHover = 'underlineHover',
  disabled = 'disabled',
}

const formatHref = (href: Url) => {
  if (href && typeof href !== 'string') {
    return url.format(href);
  }
  return href;
};
interface IProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  href?: Url;
  id?: string;
  naked?: boolean;
  legacy?: boolean;
  rel?: string;
  scroll?: boolean;
  replace?: boolean;
  shallow?: boolean;
  passHref?: boolean;
  as?: string;
  target?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: LinkVariants;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  // Create a window to still be able to do routing on client
  // To be removed when internal logic will be restored
  forceClientsideRouting?: boolean;
}

const CustomLink: React.FC<IProps> = ({
  children,
  className = '',
  href: rawHref = '/',
  id,
  naked,
  legacy = false,
  replace = false,
  shallow = false,
  passHref = false,
  as = undefined,
  scroll,
  target,
  variant = LinkVariants.noUnderlineHover,
  onClick,
  forceClientsideRouting = false,
  ...props
}) => {
  // Disabling logic based on path and external (previous default external = false) prop due to the issues caused by using next and ember app together
  // const doClientsideRouting = href && (href.startsWith('/') || href.startsWith('#')) && !external;
  // Until we find a better solution, we can force client side routing when it's in our advantage
  const doClientsideRouting = forceClientsideRouting;
  const linkClass = `${css.link} ${className} before-focus-style relative cursor-pointer leading-none transition-colors duration-500`;
  const contentClass = `${css.content} inline-grid grid-flow-col gap-1 items-center`;

  const router = useRouter();

  let href = rawHref;
  if (rawHref) {
    href = addUTMParams(rawHref, router);
  }

  if (legacy && doClientsideRouting) {
    return (
      <Link
        href={href}
        as={as ? as : undefined}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
        {...props}>
        {children}
      </Link>
    );
  }
  if (legacy && !doClientsideRouting) {
    if (children) {
      const element = React.Children.map<React.ReactNode, React.ReactNode>(children, child => {
        if (React.isValidElement(child) && child.type === 'a') {
          const hrefAttr = as ? as : formatHref(href);
          return React.cloneElement(child as React.ReactElement, { href: hrefAttr });
        }
        return child;
      });
      return <>{element}</>;
    }
    return null;
  }

  if (onClick) {
    return (
      <button
        data-testid={'link'}
        data-variant={variant}
        onClick={onClick}
        className={linkClass}
        id={id}
        {...props}>
        <span className={contentClass}>{children}</span>
      </button>
    );
  }

  if (doClientsideRouting) {
    return (
      <Link href={href} scroll={scroll}>
        <Anchor
          href={undefined}
          data-testid={'link'}
          className={linkClass}
          data-variant={variant}
          id={id}
          target={target}
          {...props}>
          {naked ? children : <span className={contentClass}>{children}</span>}
        </Anchor>
      </Link>
    );
  }

  if (!onClick && !href) {
    return (
      <span data-testid={'link'} className={linkClass} data-variant={variant} id={id} {...props}>
        {naked ? children : <span className={contentClass}>{children}</span>}
      </span>
    );
  }

  return (
    <Anchor
      data-testid={'link'}
      className={linkClass}
      data-variant={variant}
      href={typeof href === 'string' ? href : undefined}
      id={id}
      target={target}
      {...props}>
      {naked ? children : <span className={contentClass}>{children}</span>}
    </Anchor>
  );
};

export default CustomLink;
